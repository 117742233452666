import { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  performSearch,
  clearFilters,
  setShowBuilder,
  setFilter,
  setFilterGroup,
  setShowVCFundingSortModal,
  cancelSearch,
} from 'actions/search';
import {
  SLICE,
  getFilter,
  getHasValidQuery,
  getSearchRequest,
  getSearchView,
  getShowBuilder,
} from 'selectors/search';
import {
  Pane,
  Form,
  Button,
  Link,
  IconText,
  Icon,
  SearchButton,
} from 'components/common';
import entityType from 'components/common/entityTypeConstants';
import useLog, { outcomes } from 'hooks/useLog';
import { usePost, useAuth } from 'hooks';
import { searchByFullName } from 'services/api';
import licenseType from 'models/licenseType';

import SearchBuilder from './SearchBuilder';
import CreateAlert from './Alert';
import { SearchContext } from '../';
import { Filter } from '../helpers';
import {
  Actions,
  ActionButtons,
  ActionToggles,
  SearchAreaContainer,
} from '../styled';
import {
  ResearcherAutocompleteBlockContainer,
  ResearcherAutocompleteLocationBlockContainer,
  ResearcherAutocompleteLocationItem,
  SearchBar,
  SearchBarWrapper,
  TitleDiv,
} from './styled';

import Title from '../../Search/Results/Title';
import { OrganisationSearch, ActivitySearch } from '../Results/helpers';
import { getAlertToCreate } from 'selectors/alerts';
import BoolErrorMessage from '../BoolErrorMessage';
import { performBoolSearch, performPhraseSearch } from 'selectors/userSettings';
import { useCanShowVCFundingSortModal } from '../FundingSortModal/fundingSortModal';
import { SearchDiv } from 'components/Item/styled';
import ClauseLimitErrorMessage from '../ClauseLimitErrorMessage';

const SearchArea = () => {
  const dispatch = useDispatch();
  const linkRedirected = useRef(false);
  const { push } = useHistory();
  const { postSearch, postGeoSearch } = useContext(SearchContext);
  const { loading, termCountError } = useSelector(getSearchRequest);
  const showBuilder = useSelector(getShowBuilder);
  const isOrgView = OrganisationSearch(useSelector(getSearchView));
  const [showBoolError, setShowBoolError] = useState(false);
  const getHasBoolError = useSelector(performBoolSearch(SLICE));
  const getHasPhraseError = useSelector(performPhraseSearch(SLICE));

  const searchTermValueId = 'search-term';
  const hasValidQuery = useSelector(getHasValidQuery);
  const profileChecked = useSelector(getFilter('profile', 'category'));
  const activeAlert = useSelector(getAlertToCreate);

  const { hasLicense } = useAuth();
  const hasIndustryLicense = hasLicense([licenseType.industry], true);
  const hasOrgLicense = hasLicense(
    [licenseType.industry, licenseType.academic],
    true
  );
  const view = useSelector(getSearchView);

  const [
    { res: searchResults, loading: researchersLoading },
    searchResearchersByFullname,
  ] = usePost({
    url: searchByFullName,
  });

  const postLog = useLog(null, null, null, false);
  const [searchResearchers, setSearchResearchers] = useState([]);
  const [researchersSearchTerm, setResearchersSearchTerm] = useState('');
  const [debounceLoading, setDebounceLoading] = useState(false);
  const inputScroll = useRef(null);
  const searchTermRef = useRef(null);
  const canShowVCFundingSortModal = useCanShowVCFundingSortModal();

  const handleSearch = () => {
    if (showBoolError !== null && (getHasBoolError || getHasPhraseError))
      setShowBoolError(true);
    else {
      if (canShowVCFundingSortModal) {
        dispatch(setShowVCFundingSortModal(true));
      } else {
        performHandleSearch();
      }
    }
  };

  const handleCancel = () => {
    dispatch(cancelSearch());
  };

  useEffect(() => {
    return () => {
      dispatch(cancelSearch());
    };
  }, [dispatch]);

  const performHandleSearch = () => {
    dispatch(performSearch(postSearch, postGeoSearch));
  };

  const handleClear = () => {
    dispatch(clearFilters);
  };

  const handleShowBuilder = () => {
    dispatch(setShowBuilder(true));
  };

  const postProfileOpen = useCallback(
    (id, type) => {
      postLog({
        description: JSON.stringify({ entityId: id, entityType: type }),
        action: 'ViewProfileViaTypeahead',
        outcome: outcomes.success,
      });
    },
    [postLog]
  );

  const renderSearchResearcherTitle = useCallback(
    title => <span>{title}</span>,
    []
  );

  const renderSearchResearcher = useCallback(
    researcher => ({
      value: 'Researcher:' + researcher.sciLeadsSuperResearcherId,
      label: (
        <div>
          <ResearcherAutocompleteBlockContainer>
            <div>
              <Link
                showIconHint={true}
                onClick={hintClicked => {
                  linkRedirected.current = !hintClicked;
                  postProfileOpen(
                    researcher.sciLeadsSuperResearcherId,
                    entityType.researcher
                  );
                  dispatch(setFilter('term', ''));
                  setSearchResearchers([]);
                }}
                to={`/researcher/${researcher.sciLeadsSuperResearcherId}`}
              >
                {researcher.fullName}
              </Link>
              <ResearcherAutocompleteLocationBlockContainer>
                {researcher.organisationName && (
                  <ResearcherAutocompleteLocationItem>
                    <IconText
                      value={researcher.organisationName}
                      icon='company'
                    />
                  </ResearcherAutocompleteLocationItem>
                )}
                {researcher.state && researcher.country === 'United States' && (
                  <ResearcherAutocompleteLocationItem>
                    <IconText value={researcher.state} icon='flagUsa' />
                  </ResearcherAutocompleteLocationItem>
                )}
                {researcher.city && (
                  <ResearcherAutocompleteLocationItem>
                    <IconText value={researcher.city} icon='map' />
                  </ResearcherAutocompleteLocationItem>
                )}
                {researcher.country && (
                  <ResearcherAutocompleteLocationItem>
                    <IconText value={researcher.country} icon='globe' />
                  </ResearcherAutocompleteLocationItem>
                )}
              </ResearcherAutocompleteLocationBlockContainer>
            </div>
          </ResearcherAutocompleteBlockContainer>
        </div>
      ),
    }),
    [dispatch, postProfileOpen]
  );

  const renderSearchOrganisation = useCallback(
    organisation => ({
      value: 'Organisation:' + organisation.superOrganisationId,
      label: (
        <div>
          <ResearcherAutocompleteBlockContainer>
            <div>
              <Link
                showIconHint={true}
                onClick={hintClicked => {
                  linkRedirected.current = !hintClicked;
                  postProfileOpen(
                    organisation.superOrganisationId,
                    entityType.organisation
                  );
                  dispatch(setFilter('term', ''));
                  setSearchResearchers([]);
                }}
                to={`/organisation/${organisation.superOrganisationId}`}
              >
                {organisation.organisationName}
              </Link>
              <ResearcherAutocompleteLocationBlockContainer>
                {organisation.organisationCountry === 'United States' && (
                  <ResearcherAutocompleteLocationItem>
                    <IconText
                      value={organisation.organisationState}
                      icon='flagUsa'
                    />
                  </ResearcherAutocompleteLocationItem>
                )}
                {organisation.organisationCity && (
                  <ResearcherAutocompleteLocationItem>
                    <IconText
                      value={organisation.organisationCity}
                      icon='map'
                    />
                  </ResearcherAutocompleteLocationItem>
                )}
                {organisation.organisationCountry && (
                  <ResearcherAutocompleteLocationItem>
                    <IconText
                      value={organisation.organisationCountry}
                      icon='globe'
                    />
                  </ResearcherAutocompleteLocationItem>
                )}
                {organisation.superOrganisationPreviousName && (
                  <ResearcherAutocompleteLocationItem>
                    <IconText
                      value={`Previously called ${organisation.superOrganisationPreviousName}`}
                      icon='rotate'
                    />
                  </ResearcherAutocompleteLocationItem>
                )}
              </ResearcherAutocompleteLocationBlockContainer>
            </div>
          </ResearcherAutocompleteBlockContainer>
        </div>
      ),
    }),
    [dispatch, postProfileOpen]
  );

  const onSelectedValue = (value, _) => {
    if (value === searchTermValueId) {
      // if(hasValidQuery && !loading) handleSearch();
      return;
    }

    dispatch(setFilter('term', ''));
    if (linkRedirected.current) {
      linkRedirected.current = false;
      return;
    }

    const selectionParts = value.split(':');
    if (selectionParts[0] === 'Researcher') {
      postProfileOpen(selectionParts[1], entityType.researcher);
      push('/researcher/' + selectionParts[1]);
    }

    if (selectionParts[0] === 'Organisation') {
      postProfileOpen(selectionParts[1], entityType.organisation);
      push('/organisation/' + selectionParts[1]);
    }
  };

  const onSearchQueryChange = value => {
    if (value === searchTermValueId) return;

    dispatch(setFilter('term', value));
    setDebounceLoading(true);
    setSearchResearchers([]);
    setResearchersSearchTerm(value);
  };

  useEffect(() => {
    if (!researchersSearchTerm || ActivitySearch(view)) return;

    const delayDebounceFn = setTimeout(() => {
      searchResearchersByFullname({ search: researchersSearchTerm, size: 20 });
      setDebounceLoading(false);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [researchersSearchTerm, searchResearchersByFullname, view]);

  useEffect(() => {
    if (
      searchResults &&
      !researchersLoading &&
      !debounceLoading &&
      !activeAlert
    ) {
      setSearchResearchers(
        isOrgView
          ? [
              ...(searchResults &&
              searchResults.organisations &&
              searchResults.organisations.length > 0 &&
              researchersSearchTerm
                ? [
                    {
                      label: renderSearchResearcherTitle('ORGANISATIONS'),
                      options:
                        searchResults && searchResults.organisations.length > 0
                          ? searchResults.organisations.map(v =>
                              renderSearchOrganisation(v)
                            )
                          : [],
                    },
                  ]
                : []),
            ]
          : [
              ...(searchResults &&
              searchResults.researchers &&
              searchResults.researchers.length > 0 &&
              researchersSearchTerm
                ? [
                    {
                      label: renderSearchResearcherTitle('RESEARCHERS'),
                      options:
                        searchResults && searchResults.researchers.length > 0
                          ? searchResults.researchers.map(v =>
                              renderSearchResearcher(v)
                            )
                          : [],
                    },
                  ]
                : []),
            ]
      );

      inputScroll.current.scrollTo(0, 0);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchResults,
    researchersLoading,
    debounceLoading,
    researchersSearchTerm,
    renderSearchResearcherTitle,
    renderSearchResearcher,
    renderSearchOrganisation,
    isOrgView,
  ]);

  useEffect(() => {
    if (!hasOrgLicense && profileChecked) {
      dispatch(setFilterGroup('category', 'profile', false));
    }
  }, [dispatch, hasOrgLicense, profileChecked]);

  return (
    <SearchAreaContainer>
      <Pane style={{ padding: 10 }}>
        {showBuilder && (
          <SearchBuilder
            onSearch={handleSearch}
            showAdvancedFields={true}
            id={'researchers'}
          />
        )}
        <TitleDiv>
          {hasOrgLicense && (
            <Title onSearch={handleSearch} loading={loading} view={view} />
          )}
        </TitleDiv>
        <SearchBar style={{ marginTop: '10px' }}>
          <SearchBarWrapper>
            <Button
              style={{ marginRight: 10 }}
              data-testid='search-builder-button'
              type='secondary'
              size='large'
              onClick={handleShowBuilder}
              disabled={loading}
            >
              <Icon icon='magnifyingGlassPlus' color='#8D8D8D' size='sm' />
              <span style={{ marginLeft: '5px' }}>Open Search Builder</span>
            </Button>
            <SearchDiv ref={searchTermRef}>
              <Form width='100%' onSubmit={handleSearch} style={{ flex: 1 }}>
                <Filter
                  placeholder='(NGS OR "Next generation sequencing") AND CRISPR'
                  filterId='term'
                  filterType='autoComplete'
                  data-testid='filter-term'
                  size='large'
                  style={{ display: 'flex' }}
                  onChange={onSearchQueryChange}
                  onSelect={onSelectedValue}
                  loading={researchersLoading}
                  virtual={false}
                  options={
                    searchResearchers.length > 0 && !showBoolError
                      ? searchResearchers
                      : null
                  }
                  ref={inputScroll}
                  disabled={loading}
                >
                  loading
                </Filter>
              </Form>
              {showBoolError ? (
                <BoolErrorMessage
                  updateSearchQuery={onSearchQueryChange}
                  performSearch={performHandleSearch}
                  hasBoolFailed={getHasBoolError}
                  hasParseFailed={getHasPhraseError}
                  term={getFilter('term')}
                  searchTermRef={searchTermRef}
                  setShowBoolError={setShowBoolError}
                />
              ) : (
                <></>
              )}
              {termCountError && (
                <ClauseLimitErrorMessage searchTermRef={searchTermRef} />
              )}
            </SearchDiv>
          </SearchBarWrapper>

          <ActionButtons>
            <Button
              type='secondary'
              size='large'
              onClick={handleClear}
              data-testid='clear-search-button'
              disabled={loading}
            >
              Clear
            </Button>
            {
              <CreateAlert
                disabled={!hasValidQuery || loading || ActivitySearch(view)}
              />
            }
            <SearchButton
              type='primary'
              size={'large'}
              data-testid='search-button'
              loading={loading}
              disabled={!hasValidQuery || loading}
              onCancel={handleCancel}
              onSearch={handleSearch}
              location={isOrgView ? 'OrganisationSearch' : 'ResearcherSearch'}
            />
          </ActionButtons>
        </SearchBar>
        <Actions>
          <ActionToggles>
            <span style={{ lineHeight: '30px' }}>Search across: </span>
            <Filter
              helpText='Funding'
              icon='award'
              filterType='category'
              filterGroup='category'
              filterId='funding'
              disabled={loading}
            ></Filter>
            <Filter
              helpText='Tradeshows'
              icon='image'
              filterType='category'
              filterGroup='category'
              filterId='tradeshows'
              disabled={loading}
            ></Filter>
            <Filter
              helpText='Publications'
              icon='book'
              filterType='category'
              filterGroup='category'
              filterId='publications'
              disabled={loading}
            ></Filter>
            <Filter
              helpText='Clinical Trials'
              icon='trial'
              filterType='category'
              filterGroup='category'
              filterId='clinicalTrials'
              disabled={loading}
            ></Filter>
            {hasIndustryLicense && !ActivitySearch(view) && (
              <Filter
                helpText='3rd Party Data'
                icon='magnifyingGlass'
                filterType='category'
                filterGroup='category'
                filterId='profile'
                disabled={loading}
              ></Filter>
            )}
          </ActionToggles>
        </Actions>
      </Pane>
    </SearchAreaContainer>
  );
};

export default SearchArea;
