import { commonDateRanges } from 'services/inputTokens';
import { FilterItem, HistorySelect } from '../styled';
import { useState } from 'react';
import { DatePicker } from 'components/common';
import { calculateDate } from 'components/Search/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { getDateOption } from 'selectors/exportHistory';
import { setDateOption } from 'actions/exportHistory';

export default function DateFilter({ onChange, initialDateRange }) {
  const dateOption = useSelector(getDateOption);
  const [dateRange, setDateRange] = useState(initialDateRange);
  const dispatch = useDispatch();

  function changeDateOption(value) {
    dispatch(setDateOption(value));

    if (value !== 'custom' || dateRange.length > 0)
      onChange([calculateDate(value).slice(0, 1)]);
  }

  function updateDateRange(value) {
    setDateRange(value);
    onChange(calculateDate(value));
  }

  return (
    <>
      <FilterItem>
        <HistorySelect
          options={commonDateRanges}
          placeholder={'Date Range'}
          value={dateOption}
          onChange={changeDateOption}
        />
      </FilterItem>
      {dateOption === 'custom' && (
        <FilterItem>
          <DatePicker
            containerStyle={{ flex: 1 }}
            placeholder={[`Start date`, `End date`]}
            type='range'
            onChange={updateDateRange}
            value={dateRange}
          />
        </FilterItem>
      )}
    </>
  );
}
